import React, { useEffect } from "react";
import offerbackgraundbanner from "../images/aboutusbanner.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { GetGeneralDocsData } from "../ReduxToolkit/Slices/Contentmanage/ContentManage.action";

const Terms = () => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetGeneralDocsData());
  }, []);
  let Content = useSelector((store) => store?.Content);

  console.log(Content);

  return (
    <div className="bg-[#FAFAFA] overflow-hidden">
      <div className="w-full relative mx-auto ">
        <LazyLoadImage
          effect="blur"
          className=" object-cover w-full h-[40vh] md:h-[60vh]"
          objectFit="cover"
          src={offerbackgraundbanner}
          alt="giveaway"
          height={"100%"}
          width={"100%"}
        />
        <div className="absolute top-0 m-auto w-full   h-full flex">
          <div className="  text-zinc-50 w-full  md:1/2 flex flex-col justify-center align-middle text-center m-auto ">
            <h2 className="font-extrabold text-cxl md:text-c_xl  ">
              Terms & Conditions
            </h2>
            <p className="text-csm  md:text-c_sm  w-[95%] md:w-[50%] lg:w-[30%]  text-center m-auto">
              It is a long established fact that a reader will be distracted by
              the readable content of a.
            </p>
          </div>
        </div>
      </div>

      <div className="w-full">
        <div className="w-[90%] mx-auto py-14">
          <p
            className="text-dark-text "
            dangerouslySetInnerHTML={{
              __html: Content?.GeneralDocs?.terms_condition
                ? Content?.GeneralDocs?.terms_condition
                : "Not Data !",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Terms;
