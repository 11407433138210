import moment from "moment"
export const formatTime = (timeString) => {
  const utcTime = moment.utc(timeString, "HH:mm:ss");
  const localTime = utcTime.local();
  const formattedHours = localTime.format("h");
  const minutes = localTime.format("mm");
  const period = localTime.format("A");
  return `${formattedHours}:${minutes} ${period}`;
}
export const discountcalculate = (actualprice, sellingprice) => {
  const discount = Math.floor(
    ((actualprice - sellingprice) / actualprice) * 100
  )
  return discount;
}