import React, { useState, useEffect, useRef } from "react";
import showPass from "../../images/eye.svg";
import hidePass from "../../images/eyeTwo.svg";
import searchIcon from "../../images/search.svg";
import tag from "../../images/tag.svg";
import CustomDropDown from "../CustomDropDown";
import Autocomplete from "react-google-autocomplete";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GetRegionList,
  Signup,
} from "../../ReduxToolkit/Slices/Auth/Auth.action";
import { useDispatch, useSelector } from "react-redux";
import Constant from "../../utils/Constant";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import LoginModal from "./LoginModal";
import toast from "react-hot-toast";
import { RxCrossCircled } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
const SignupModal = ({ closeSignupModal, switchToLogin }) => {
  let dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [regionLoc, setRegionLoc] = useState("");
  const [cityLoc, setCityLoc] = useState("");
  const [defaultCountry, setDefaultCountry] = useState("in");
  const [pinLoc, setPinLoc] = useState("");
  const [latLng, setLatLng] = useState({ lat: null, lng: null });
  const [addressAuto, setAddressAuto] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const modalRef = useRef(null);
  let AuthData = useSelector((store) => store?.Auth);
  useEffect(() => {
    dispatch(GetRegionList());
  }, []);

  const getAddress = (lat, long) => {
    if (lat && long) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${Constant.GOOGLEMAPKEY.ApiKey}`
      )
        .then((res) => res.json())
        .then((address) => {
          setLatitude(address.results[0]?.geometry?.location?.lat);
          setLongitude(address.results[0]?.geometry?.location?.lng);

          const addressAuto = address.results?.find((i) => {
            return i.types.some((i) => i === "route");
          });
          setAddressAuto(addressAuto?.formatted_address);

          const regionMap = address.results[1].address_components?.find((i) => {
            return i.types.some((i) => i === "sublocality");
          });
          setRegionLoc(regionMap?.long_name);
          const cityMap = address.results[1].address_components?.find((i) => {
            return i.types.some((i) => i === "administrative_area_level_3");
          });
          setCityLoc(cityMap?.long_name);
          const PINmap = address.results[1].address_components?.find((i) => {
            return i.types.some((i) => i === "postal_code");
          });
          setPinLoc(PINmap?.long_name);
        });
    }
  };

  // useEffect(() => {
  //   if (latitude && longitude) {
  //     const place = {
  //       geometry: {
  //         location: {
  //           lat: parseFloat(latitude),
  //           lng: parseFloat(longitude),
  //         },
  //       },
  //       formatted_address: addressAuto,
  //     };
  //     handlePlaceSelected(place);
  //   }
  // }, [latitude, longitude]);

  // const handlePlaceSelected = (place) => {
  //   if (place.geometry) {
  //     let lat, lng;
  //     if (typeof place.geometry.location.lat === "function") {
  //       lat = place.geometry.location.lat();
  //       lng = place.geometry.location.lng();
  //     } else {
  //       lat = place.geometry.location.lat;
  //       lng = place.geometry.location.lng;
  //     }
  //     setLatLng({ lat, lng });
  //     setAddressAuto(place.formatted_address);
  //     setLatitude(lat);
  //     setLongitude(lng);
  //   } else {
  //     const lat = place.lat;
  //     const lng = place.lng;
  //     setLatLng({ lat, lng });
  //     setAddressAuto(place.address);
  //     setLatitude(lat);
  //     setLongitude(lng);
  //   }
  // };

  useEffect(() => {
    // getAddress(latitude, longitude);
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        // closeSignupModal();
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  let [authload, setauthload] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      number: "",
      password: "",
      confirmPassword: "",
      region: null,
      address: "",
      acceptTerms: false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(
          /^[a-zA-Z0-9\s]*$/,
          "Name should not contain any symbolic characters"
        )
        .required("Name is required!"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required!"),
      number: Yup.string()
        .min(2, "Mobile number must be at least 4 digits")
        .required("Mobile number is required!"),
      password: Yup.string()
        .required("Password is required!")
        .min(8, "Password should contain at least 8 characters")
        .max(20, "Password must contain at most 20 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
          /[\W_]/,
          "Password must contain at least one special character (e.g., !, @, #, $, etc.)"
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required!"),
      region: Yup.string().required("Select Region !"),
      region_id: Yup.number().required("Select Region !"),
      address: Yup.string().required("Address is required!"),
      acceptTerms: Yup.bool().oneOf(
        [true],
        "You must accept the terms and conditions"
      ),
    }),
    onSubmit: async (values, { resetForm }) => {
      let SignupData = new FormData();
      SignupData.append("email", values?.email);
      SignupData.append("password", values?.password);
      SignupData.append("phno", values?.number);
      SignupData.append("region_id", values?.region_id);
      SignupData.append("address", values?.address);
      SignupData.append("name", values?.name);
      SignupData.append("phno_cc", values?.phno_cc);
      SignupData.append("role", 1);
      setauthload(true);
      dispatch(Signup(SignupData))
        .unwrap()
        .then((result) => {
          if (result?.s == 1 && result?.r?.token) {
            setauthload(false);
            localStorage.setItem("SaveOn_Web_Cread", JSON.stringify(result?.r));
            toast(`${result?.m} Welcome to SaveOn !`, {
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
                textAlign: "center",
              },
            });
            resetForm();
            closeSignupModal();
          } else {
            setauthload(false);
            toast.error(result?.m, {
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          }
        });
    },
  });

  let navigate = useNavigate();

  let RedirectToPrivacy = () => {
    navigate("/policy");
    closeSignupModal();
  };

  let RedirectToTermsandCondition = () => {
    navigate("/terms-condition");
    closeSignupModal();
  };
console.log(formik);

  return (
    <div className="fixed z-10 inset-0  bg-[rgba(54,0,17,0.84)]  flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-white rounded-[32px] p-8 relative w-[90%] sm:w-[500px] tracking-wide border-dashed border-2 border-[rgba(14,47,67,0.5)] shadow-[10px_8px_0_0_rgba(255,255,255,0.42)]"
      >
        <div className="text-2xl text-white absolute -top-14 left-24 flex gap-1">
          <span className="">Signup </span> to get exclusive offers{" "}
          <img src={tag} width={25} alt="tag" />
        </div>
        <div className="flex justify-end absolute right-8 z-50 top-4 ">
          <RxCrossCircled
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={closeSignupModal}
          />
        </div>

        <div className="custom-scrollbar pr-2 overflow-auto w-full h-[70vh] tracking-wide">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit();
              return false;
            }}
          >
            {/* --------- Name Field --------- */}
            <div className="mt-6 relative">
              <label htmlFor="name" className="text-left text-dark-text mb-2">
                Name
              </label>
              <input
                className="border   rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
                type="text"
                name="name"
                placeholder="Enter Full Name"
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                onKeyUp={formik.handleBlur}
                style={{ background: "rgba(254,241,248,1)" }}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-red-600">{formik.errors.name}</div>
              )}
            </div>

            {/* --------- Email Field --------- */}
            <div className="mt-6 relative">
              <label htmlFor="email" className="text-left text-dark-text mb-2">
                Email Address
              </label>
              <input
                className="border border-[rgba(137,105,120,1)] rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
                type="text"
                name="email"
                placeholder="Enter Email Address"
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                onKeyUp={formik.handleBlur}
                style={{ background: "rgba(254,241,248,1)" }}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="text-red-600">{formik.errors.email}</div>
              )}
            </div>

            {/* --------- Number Field --------- */}
            <div className="mt-6 relative">
              <label
                htmlFor="number"
                className="text-left text-dark-text  mb-2"
              >
                Enter Number
              </label>

              <PhoneInput
                name="number "
                country={defaultCountry}
                placeholder="Enter your Number"
                value={formik.values.phno_cc + formik.values.number}
                onChange={(value, country) => {
                  const countryCodeLength = country.dialCode.length;
                  const phno_cc = value.slice(0, countryCodeLength);
                  const phno = value.slice(countryCodeLength);
                  formik.setFieldValue("number", phno);
                  formik.setFieldValue("phno_cc", phno_cc);
                }}
                onKeyUp={formik.handleBlur}
                inputClass="inputClassCountry"
                style={{
                  background: "rgba(254,241,248,1)",
                  width: "100%",
                
                }}
              />
              {formik.touched.number && formik.errors.number && (
                <div className="text-red-600">{formik.errors.number}</div>
              )}
            </div>

            {/* --------- Password Field --------- */}

            <div className="mt-6 relative">
              <label
                htmlFor="password"
                className="text-left text-dark-text mb-2"
              >
                Password
              </label>
              <input
                className="border border-[rgba(137,105,120,1)] rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
                name="password"
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                onKeyUp={formik.handleBlur}
                style={{ background: "rgba(254,241,248,1)" }}
              />
              <img
                onClick={() => setShowPassword(!showPassword)}
                className="absolute top-[45px] right-4 cursor-pointer"
                src={showPassword ? hidePass : showPass}
                alt="show password icon"
              />
              {formik.touched.password && formik.errors.password && (
                <div className="text-red-600">{formik.errors.password}</div>
              )}
            </div>

            {/* --------- Confirm Password Field --------- */}

            <div className="mt-6 relative">
              <label
                htmlFor="confirmPassword"
                className="text-left text-dark-text mb-2"
              >
                Confirm Password
              </label>
              <input
                className="border border-[rgba(137,105,120,1)] rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm your password"
                name="confirmPassword"
                onChange={(e) => {
                  const { value } = e.target;
                  if (value.trimStart() !== value) {
                    e.target.value = value.trimStart();
                  }
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.confirmPassword}
                onKeyUp={formik.handleBlur}
                style={{ background: "rgba(254,241,248,1)" }}
              />
              <img
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                className="absolute top-[45px] right-4 cursor-pointer"
                src={showConfirmPassword ? hidePass : showPass}
                alt="show password icon"
              />

              {formik.touched.confirmPassword &&
                formik.errors.confirmPassword && (
                  <div className="text-red-600">
                    {formik.errors.confirmPassword}
                  </div>
                )}
            </div>

            {/* --------- Region Field --------- */}

            <div className="mt-6 relative">
              <label htmlFor="region" className="text-left text-dark-text mb-2">
                Region
              </label>
              <CustomDropDown
                value={formik.values.region}
                onChange={(event, selectedId) => {
                  formik.setFieldValue("region", event.target.value);
                  formik.setFieldValue("region_id", selectedId);
                }}
                onBlur={formik.handleBlur}
                NoData="No region available"
                menuList={AuthData?.RegionList}
                placeholder={"Select Region"}
                style={{ background: "rgba(254,241,248,1) " }}
              />
              {formik.touched.region && formik.errors.region && (
                <div className="text-red-600">{formik.errors.region}</div>
              )}
            </div>
            {/* --------- Address Field --------- */}
            <div className="mt-6 relative">
              <label
                htmlFor="autocomplete"
                className="text-left text-dark-text mb-2"
              >
                Address
              </label>
              <div className="relative">
                <Autocomplete
                  placeholder="Address"
                  id="autocomplete"
                  name="address"
                  value={formik.values.address}
                  className="inputClassAddress"
                  componentRestrictions={{ country: "in" }}
                  // options={{
                  //   types: ["geocode", "establishment"],
                  // }}
                  apiKey={Constant.GOOGLEMAPKEY.ApiKey}
                  onChange={formik.handleChange}
                  onKeyUp={formik.handleBlur}
                  // onPlaceSelected={(place) => {
                  //   const regionMap = place?.address_components?.find((i) => {
                  //     return i.types.some((i) => i === "sublocality");
                  //   });
                  //   setRegionLoc(regionMap?.long_name);
                  //   const cityMap = place?.address_components?.find((i) => {
                  //     return i.types.some(
                  //       (i) => i === "administrative_area_level_3"
                  //     );
                  //   });
                  //   setCityLoc(cityMap?.long_name);
                  //   const PINmap = place?.address_components?.find((i) => {
                  //     return i.types.some((i) => i === "postal_code");
                  //   });
                  //   setPinLoc(PINmap?.long_name);
                  //   setLatLng({
                  //     lat: place?.geometry.location.lat(),
                  //     lng: place?.geometry.location.lng(),
                  //   });

                  //   setAddressAuto(place?.formatted_address);
                  //   setLatitude(place?.geometry.location.lat());
                  //   setLongitude(place?.geometry.location.lng());
                  //   formik.setFieldValue("address", place?.formatted_address);
                  // }}
                  style={{
                    borderRadius: "8px",
                    outline: "none",
                    border: `1px solid red`,
                    width: "100%",
                    height: "56px",
                    borderRadius: "10px",
                    border: "1px solid rgba(137,105,120,1)",
                    background: "rgba(254,241,248,1)",
                    paddingLeft: "50px",
                    fontSize: "18px",
                  }}
                  invalid={
                    formik.touched.address && formik.errors.address
                      ? true
                      : false
                  }
                />
                <img
                  className="absolute top-[50%] left-4 transform -translate-y-1/2"
                  src={searchIcon}
                  alt="search icon"
                />
              </div>
              {formik.touched.address && formik.errors.address && (
                <div className="text-red-600">{formik.errors.address}</div>
              )}
            </div>

            {/* --------- Terms and Conditions Checkbox ---------- */}
            <div className="mb-4 mt-4">
              <label className="flex items-center">
              <input
  type="checkbox"
  name="acceptTerms"
  className="form-checkbox h-4 w-4 border-2 border-primary-blue focus:border-primary-dark-blue cursor-pointer"
  checked={formik.values.acceptTerms}
  onChange={(e) => {
    formik.setFieldValue("acceptTerms", e.target.checked);
  }}
  style={{
    color: "rgba(209, 5, 87, 1)",
  }}
/>
                <span className="text-dark-text text-sm font-semibold cursor-pointer select-none ml-2">
                  I Understand the{" "}
                  <span
                    onClick={RedirectToTermsandCondition}
                    className="cursor-pointer text-primary-blue font-semibold"
                  >
                    {" "}
                    Terms of Services{" "}
                  </span>{" "}
                  and{" "}
                  <span
                    onClick={RedirectToPrivacy}
                    className="text-primary-blue font-semibold cursor-pointer"
                  >
                    {" "}
                    Privacy Policy{" "}
                  </span>
                </span>
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <div className="text-red-500 text-sm mt-1">
                  {formik.errors.acceptTerms}
                </div>
              )}
            </div>

            <div className="mt-4 flex justify-between items-center">
              <div>
                <p className="text-dark-text font-semibold">
                  Already have an account ?{" "}
                  <span
                    onClick={switchToLogin}
                    className="text-primary-blue cursor-pointer"
                  >
                    Log In
                  </span>
                </p>
              </div>
              <div>
                <button
                  disabled={authload}
                  className="bg-dark-red-bg py-2 px-6 text-[#FFF] rounded-lg"
                  type="submit"
                >
                  {authload ? "Loading" : "Signup"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupModal;
