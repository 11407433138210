import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetsubscriptionPlans } from "../../ReduxToolkit/Slices/Subscription/Subscription.action";
import PlanCard from "../PlanCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import "react-lazy-load-image-component/src/effects/blur.css";
import { RxCrossCircled } from "react-icons/rx";

const RedeemModal = ({ showRedeemModal, setShowRedeemModal, Subscribed }) => {
  const modalRef = useRef(null);
  const dispatch = useDispatch();
  const Subscription = useSelector((store) => store?.Subscription);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowRedeemModal(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [setShowRedeemModal]);

  useEffect(() => {
    if (showRedeemModal) {
      dispatch(GetsubscriptionPlans());
    }
  }, [showRedeemModal, dispatch]);

  if (!showRedeemModal) {
    return null;
  }

  const closethismodal = () => {
    setShowRedeemModal(false);
  };

  // Determine the number of items to display based on the value of Subscribed
  const itemsToDisplay = Subscribed
    ? 3
    : Subscription?.planDetails?.length || 0;

  return (
    <div className="fixed z-[105] inset-0 bg-[rgba(54,0,17,0.84)]  flex items-center justify-center">
      <div
        ref={modalRef}
        className="bg-transparent rounded-[32px] relative w-[90%] tracking-wide "
      >
        <div className="flex items-center justify-between ">
          <p className=" mb-4 font-bold text-sm lg:text-c_x transition-colors text-white duration-300 group-hover:text-blue-700">
            Buy Coupons
          </p>
          <RxCrossCircled
            onClick={closethismodal}
            className="text-3xl text-white cursor-pointer"
          />
        </div>
        <div className="bg-white flex justify-center items-center px-8 py-4 h-[550px] md:h-[75vh] rounded-3xl">
          <Swiper
            slidesPerView={3}
            pagination={{ dynamicBullets: true }}
            modules={[Pagination, Navigation]}
            navigation={true}
            breakpoints={{
              320: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 4,
                spaceBetween: 30,
              },
            }}
            className="mySwiper "
          >
            <div className="flex  justify-center items-center  border-2 border-red-600  ">
              {Subscription?.planDetails
                ?.slice(0, itemsToDisplay)
                .map((el, index) => (
                  <SwiperSlide
                    key={index}
                    style={{ height: "400px !important" }}
                  >
                    <PlanCard planData={el} isreedem={true} />
                  </SwiperSlide>
                ))}
            </div>

          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default RedeemModal;
