import React, { useState } from "react";
import listIcon from "../images/list.png";
import ornaments from "../images/Ornaments.svg";
import { useDispatch, useSelector } from "react-redux";

import "./Animationcss.css";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LoginModal from "./modal/LoginModal";
import ForgotPassword from "./modal/ForgotPassword";
import SignupModal from "./modal/SignupModal";
const SubscriptionPlanCard = ({ planData }) => {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const openSignupModal = () => setIsSignupOpen(true);
  const closeSignupModal = () => setIsSignupOpen(false);

  const openLoginModal = () => setIsLoginOpen(true);
  const closeLoginModal = () => setIsLoginOpen(false);

  const openForgotPassModal = () => setIsForgotPasswordOpen(true);
  const closeForgotPassModal = () => setIsForgotPasswordOpen(false);

  const switchToForgotPass = () => {
    closeLoginModal();
    openForgotPassModal();
  };
  const switchToLogin = () => {
    closeSignupModal();
    closeForgotPassModal();
    openLoginModal();
  };
  const switchToSignup = () => {
    closeLoginModal();
    openSignupModal();
  };

  const CheckIsLogin = () => {
    const isLoggedIn = localStorage.getItem("SaveOn_Web_Cread");
    if (isLoggedIn) {
      return true;
    } else {
      // toast("Please Login !", {
      //   icon: "❌",
      //   style: {
      //     borderRadius: "10px",
      //     background: "#333",
      //     color: "#fff",
      //   },
      // });
      openLoginModal();
      return false;
    }
  };
  let navigate = useNavigate();
  let [IsNavigateLoad, setIsNavigateLoad] = useState(false);
  let BuySubscription = () => {
    let islogin = CheckIsLogin();
    if (islogin) {
      if (authdata?.UserDetailsByid?.is_subscribe == 1) {
        toast("To change the plan, you need to cancel the previous plan !", {
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        return;
      }

      if (authdata) setIsNavigateLoad(true);
      setTimeout(() => {
        setIsNavigateLoad(false);
        navigate("/checkout", { state: { planData } });
      }, 3000);
    }
  };
  let logindata = JSON.parse(localStorage.getItem("SaveOn_Web_Cread"));
  let authdata = useSelector((store) => store?.Auth);

  return (
    <>
      {IsNavigateLoad && (
        <div className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center  bg-black bg-opacity-50">
          <span className="loader"> </span>
        </div>
      )}
      {isLoginOpen && (
        <LoginModal
          closeLoginModal={closeLoginModal}
          switchToSignup={switchToSignup}
          switchToForgotPass={switchToForgotPass}
        />
      )}
      {isForgotPasswordOpen && (
        <ForgotPassword
          closeForgotPassModal={closeForgotPassModal}
          switchToForgotPass={switchToForgotPass}
        />
      )}
      {isSignupOpen && (
        <SignupModal
          closeSignupModal={closeSignupModal}
          switchToLogin={switchToLogin}
        />
      )}
      <div
        className={`w-[100%] m-auto px-8 md:px-4 lg:px-8 pt-4 pb-8  lg:pb-14 shadow-[0_2px_6px_rgba(0,0,0,0.24)] rounded-[42px] bg-white flex flex-col gap-6 relative transition-all duration-500 hover:shadow-lg ${
          planData?.plan_type === 3
            ? "border-[2px] border-transparent hover:border-primary-blue"
            : "border-[2px] border-transparent hover:border-dark-blue"
        }`}
      >
        <div
          className={`${
            planData?.plan_type === 4
              ? " bg-[rgba(254,229,243,1)]"
              : planData?.plan_type === 5
              ? "bg-[rgba(255,48,147,1)]"
              : planData?.plan_type === 7
              ? "bg-dark-blue"
              : "bg-dark-blue"
          }    relative py-3 xl:py-4 px-8 xl:px-10 -top-12 w-fit mx-auto rounded-[14px] font-medium text-lg ${
            planData?.plan_type === 1
              ? "text-[rgba(255,48,147,1)]"
              : planData?.plan_type === 2
              ? "text-white"
              : planData?.plan_type === 3
              ? "bg-dark-blue, text-white"
              : planData?.plan_type === 4
              ? "bg-secondary-blue"
              : "text-white"
          }`}
        >
          {planData?.plan_name  }
        </div>
        <div className="absolute gap-2 right-0 top-14">
          <img className="" src={ornaments} />
        </div>
        <div className="flex gap-2 items-baseline border-b pb-3">
          <span
            className={`text-[30px] xl:text-[50px] font-bold  ${
              planData?.plan_type === 4
                ? "text-[rgba(255,48,147,1)]"
                : "text-dark-blue"
            }  `}
          >
            ${planData?.amount}
          </span>
          <span className=" text-[16px] xl:text-[20px]">
            / {planData?.days} days
          </span>
        </div>
      

        <div className="flex flex-col gap-1 ml-2 md:text-[14px] xl:text-[20px] h-[30vh] overflow-auto">
          {planData?.description
            ?.split("\n")
            ?.map((desc) => desc.trim())
            ?.filter((desc) => desc) // Remove empty lines if any
            ?.map((pl, index) => (
              <div key={index} className="flex items-center gap-2 h-14">
                <img
                  className="w-[16px] xl:w-[20px]"
                  src={listIcon}
                  alt="list icon"
                />
                <span className="text-c_sm">{pl}</span>
              </div>
            ))}
        </div>

        <div className="w-full flex items-center justify-center">
          <button
            disabled={
              authdata?.UserDetailsByid?.plan_type === planData?.plan_type
            }
            onClick={BuySubscription}
            className={`${
              authdata?.UserDetailsByid?.plan_type === planData?.plan_type
                ? "bg-gray-400 border-none  hover:bg-primary-blue  hover:text-white"
                : "cursor-pointer"
            } rounded-[8px] border-2  hover:bg-primary-blue hover:text-white border-primary-blue size-fit px-4 py-2 ease-in-out duration-500`}
          >
            {authdata?.UserDetailsByid?.plan_type === planData?.plan_type
              ? "Active Plan"
              // : !authdata?.UserDetailsByid?.plan_type === 4 ||
              //   !authdata?.UserDetailsByid?.plan_type === 5
              // ? 
              
              // "Join Now"
              : "Join Now"}
          </button>
        </div>
      </div>
    </>
  );
};

export default SubscriptionPlanCard;
