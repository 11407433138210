import React from 'react';

const CustomDropDown = (props) => {
    const { value, onChange, NoData, menuList = [], placeholder } = props;

    return (
        <select
            className="border  border-desc-text rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
            value={value}
            onChange={(event) => {
                const selectedId = menuList.find(elem => elem.name === event.target.value)?.id;
                onChange(event, selectedId);
            }}
            style={{
                background: "rgba(254,241,248,1)" ,
                   color: "rgba(209, 5, 87, 1)",
                   
                 }}
        >
            <option value="">{placeholder}</option>
            {menuList.length > 0 ? (
                menuList.map((elem, index) => (
                    <option key={index} value={elem.name}>{elem.name}</option>
                ))
            ) : (
                <option value="" disabled>{NoData}</option>
            )}
        </select>
    );
};

export default CustomDropDown;
