import React, { useEffect, useState } from "react";
import listIcon from "../images/list.png";
import ornaments from "../images/Ornaments.svg";
import { useDispatch, useSelector } from "react-redux";
import { CreatThisplanOrder } from "../ReduxToolkit/Slices/Subscription/Subscription.action";
import "./Animationcss.css";
import { GetUserProfileDetails } from "../ReduxToolkit/Slices/Auth/Auth.action";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import LoginModal from "./modal/LoginModal";
import ForgotPassword from "./modal/ForgotPassword";
import SignupModal from "./modal/SignupModal";
const PlanCard = ({ planData, isreedem }) => {
  let dispatch = useDispatch();
  let [loadRedirect, setloadRedirect] = useState(false);
  // -------- Is Check Login -------------
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const openSignupModal = () => setIsSignupOpen(true);
  const closeSignupModal = () => setIsSignupOpen(false);

  const openLoginModal = () => setIsLoginOpen(true);
  const closeLoginModal = () => setIsLoginOpen(false);

  const openForgotPassModal = () => setIsForgotPasswordOpen(true);
  const closeForgotPassModal = () => setIsForgotPasswordOpen(false);

  const switchToForgotPass = () => {
    closeLoginModal();
    openForgotPassModal();
  };
  const switchToLogin = () => {
    closeSignupModal();
    closeForgotPassModal();
    openLoginModal();
  };
  const switchToSignup = () => {
    closeLoginModal();
    openSignupModal();
  };

  const CheckIsLogin = () => {
    const isLoggedIn = localStorage.getItem("SaveOn_Web_Cread");
    if (isLoggedIn) {
      return true;
    } else {
      // toast("Please Login !", {
      //   icon: "❌",
      //   style: {
      //     borderRadius: "10px",
      //     background: "#333",
      //     color: "#fff",
      //   },
      // });
      setIsLoginOpen(true);

      return false;
    }
  };
  let navigate = useNavigate();

  let [IsNavigateLoad, setIsNavigateLoad] = useState(false);
  let BuyOrder = () => {
    let islogin = CheckIsLogin();
    if (islogin) {
      setIsNavigateLoad(true);
      setTimeout(() => {
        setIsNavigateLoad(false);
        navigate("/checkout", { state: { planData } });
      }, 3000);
      // let planFormdata = new FormData();
      // if ([1, 2, 3].includes(planData?.plan_type)) {
      //   planFormdata.append("plan_id", planData?.plan_type);
      //   setloadRedirect(true);
      //   dispatch(CreatThisplanOrder(planFormdata))
      //     .unwrap()
      //     .then((result) => {
      //       if (result?.s === 1) {
      //         console.log(result?.r?.links);
      //         const approveLink = result.r.links.find(
      //           (link) => link.rel === "approve"
      //         );
      //         if (approveLink) {
      //           window.location.href = approveLink.href;
      //           setloadRedirect(false);
      //         } else {
      //           setloadRedirect(false);
      //         }
      //       } else {
      //         setloadRedirect(false);
      //       }
      //     });
      // }
    }
  };
  let logindata = JSON.parse(localStorage.getItem("SaveOn_Web_Cread"));
  let authdata = useSelector((store) => store?.Auth);
  useEffect(() => {
    dispatch(GetUserProfileDetails(logindata?.id));
  }, []);
  console.log(planData?.description);
  let planarray = planData?.description?.split("/n");
  return (
    <>
      {IsNavigateLoad && (
        <div className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center  bg-black bg-opacity-50">
          <span className="loader"></span>
        </div>
      )}
      {isLoginOpen && (
        <LoginModal
          closeLoginModal={closeLoginModal}
          switchToSignup={switchToSignup}
          switchToForgotPass={switchToForgotPass}
        />
      )}
      {isForgotPasswordOpen && (
        <ForgotPassword
          closeForgotPassModal={closeForgotPassModal}
          switchToForgotPass={switchToForgotPass}
        />
      )}
      {isSignupOpen && (
        <SignupModal
          closeSignupModal={closeSignupModal}
          switchToLogin={switchToLogin}
        />
      )}

      <div
        className={`w-[95%] m-auto px-8 md:px-4 lg:px-8 pt-4 pb-8  lg:pb-14 shadow-[0_2px_6px_rgba(0,0,0,0.24)] rounded-[42px] bg-white flex flex-col gap-6 relative transition-all duration-500 hover:shadow-lg ${
          planData?.plan_type === 1
            ? "border-[2px] border-transparent hover:border-primary-blue"
            : planData?.plan_type === 2
            ? "border-[2px] border-transparent hover:border-primary-blue"
            : "border-[2px] border-transparent hover:border-dark-blue"
        }`}
      >
        <div
          className={`${
            planData?.plan_type === 1
              ? " bg-[rgba(254,229,243,1)]"
              : planData?.plan_type === 2
              ? "bg-[rgba(255,48,147,1)]"
              : planData?.plan_type === 3
              ? "bg-dark-blue"
              : "bg-dark-blue"
          }    relative py-3 xl:py-4 px-8 xl:px-10 -top-12 w-fit mx-auto rounded-[14px] font-medium text-lg ${
            planData?.plan_type === 1
              ? "text-[rgba(255,48,147,1)]"
              : planData?.plan_type === 2
              ? "text-white"
              : planData?.plan_type === 3
              ? "bg-dark-blue, text-white"
              : planData?.plan_type === 4
              ? "bg-secondary-blue"
              : "text-white"
          }`}
        >
          {planData?.plan_name}
        </div>
        <div className="absolute gap-2 right-0 top-14">
          <img className="" src={ornaments} />
        </div>
        <div className="flex gap-2 items-baseline border-b pb-3">
          <span
            className={`text-[30px] xl:text-[50px] font-bold  ${
              planData?.plan_type === 1
                ? "text-primary-blue"
                : planData?.plan_type === 2
                ? "text-[rgba(209,5,87,1)]"
                : "text-dark-blue"
            }  `}
          >
            ${planData?.amount}
          </span>
          <span
            className={`text-[16px] xl:text-[20px] ${
              planData?.plan_type === 1
                ? "text-primary-blue"
                : planData?.plan_type === 2
                ? "text-[rgba(209,5,87,1)]"
                : "text-dark-blue"
            } `}
          >
            / {planData?.days} days
          </span>
        </div>
        <div className="flex flex-col gap-6 ml-4 md:text-[14px] xl:text-[20px]">
          <div className="flex flex-col gap-1 ml-2 md:text-[14px] xl:text-[20px] h-[30vh] overflow-auto">
            {planData?.description
              ?.split("\n")
              ?.map((desc) => desc.trim())
              ?.filter((desc) => desc) // Remove empty lines if any
              ?.map((pl, index) => (
                <div key={index} className="flex items-center gap-2 h-14">
                  <img
                    className="w-[16px] xl:w-[20px]"
                    src={listIcon}
                    alt="list icon"
                  />
                  <span className="text-c_sm">{pl}</span>
                </div>
              ))}
          </div>
        </div>
        <div className="w-full flex items-center justify-center">
          <button
            disabled={loadRedirect}
            onClick={BuyOrder}
            className={`cursor-pointer  rounded-[8px] border-2 bg-light-blue text-primary-blue border-primary-blue size-fit px-4 py-2 hover:bg-primary-blue hover:text-white ease-in-out duration-500`}
          >
            Join Now
          </button>
        </div>
      </div>
    </>
  );
};

export default PlanCard;
