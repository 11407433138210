import React, { useEffect } from "react";
import { GetmyPaymentHistory } from "../../ReduxToolkit/Slices/Subscription/Subscription.action";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

const PaymentHistory = () => {
  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetmyPaymentHistory());
  }, []);
  let subscriptiondetails = useSelector((store) => store?.Subscription);

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
        <thead className=" text-dark-text capitalize bg-transparent">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-lg text-center font-medium"
            >
              Plans
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-lg text-center font-medium"
            >
              Purches Date
            </th>

            <th
              scope="col"
              className="px-6 py-3 text-lg text-center font-medium"
            >
              Transaction Id
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-lg text-center font-medium"
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {subscriptiondetails?.myPaymentHistory?.map((history, index) => (
            <tr
              key={index}
              className={`${
                index % 2 === 0 ? "bg-light-blue" : "bg-transparent"
              }`}
            >
              <th
                scope="row"
                className="px-6 border relative  py-4 text-center text-xl font-medium text-dark-blue whitespace-nowrap"
              >
                {history?.type == 2 ? (
                  <p className="absolute top-0 text-[12px] left-0">
                    {" "}
                    Subscription
                  </p>
                ) : (
                  ""
                )}
{/* 
                {history?.plan_type === 1
                  ? "Silver"
                  : history?.plan_type === 2
                  ? "Gold"
                  : history?.plan_type === 3
                  ? "Platinum"
                  : history?.plan_type === 4
                  ? "Entry"
                  : history?.plan_type === 5
                  ? "Bronze"
                  : ""} */}


                  {history?.plan_name}
              </th>
              <td className="px-6 py-4  text-center text-primary-blue text-lg font-medium">
                {moment(history?.start_date)
                  .local()
                  .format("MMMM Do YYYY, hh:mm A") || "--"}
              </td>
              <td className="px-6 py-4 text-center text-dark-blue text-lg font-medium">
                {history?.transaction_id}
              </td>
              <td className="px-6  text-center py-4 text-2xl text-primary-blue font-semibold">
                ${history?.amount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentHistory;
