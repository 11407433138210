import React from "react";
import planCoupen from "../images/planCoupen.svg";
const SectionTitle = ({ title }) => {
  return (
    <div className="w-full flex justify-start gap-4 items-center ">
      <h2 className="font-bold text-cx lg:text-c_x transition-colors text-dark-text duration-300 group-hover:text-blue-700">
        {title}
      </h2>
      {title=="One-off Plans"?<img src={planCoupen}  alt="" />:""}
    </div>
  );
};

export default SectionTitle;
