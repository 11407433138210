import React from 'react'
 

const HowtoCard = ({image, frame,title,desc}) => {
  return (
<div className=' group p-4 md:p-8 w-[100%]  flex flex-col  items-center gap-2 bg-[rgba(255,255,255,1)] shadow-[0_2px_4px_rgba(0,0,0,0.12)] rounded-[14px] relative transition-transform duration-300 transform hover:scale-105 hover:border-dark-blue'>
<div className='absolute -z-[1] -top-5 -left-5 rounded-full drop-shadow-[0_2px_4px_rgba(0,0,0,0.12)]' >
    <img className='w-full max-w-12 md:max-w-14 xl:max-w-14 -top-5 -left-5' src={frame} />
    </div>
        <img className='w-full max-w-12 md:max-w-14 xl:max-w-14 absolute -top-5 -left-5 ' src={frame} />
        <img
        className="w-full max-w-14 transition-all duration-200 group-hover:rounded-full group-hover:border-4 group-hover:border-dark-blue"
        src={image}
    />

        <h3 className='font-semibold md:text-[24px] xl:text-[32px] text-dark-text' >{title}</h3>
        <p className='text-desc-text md:text-[16px] xl:text-[20px] font-medium text-center' >{desc}</p>
    </div>
  )
}

export default HowtoCard;