import React, { useEffect, useRef, useState } from "react";
import { RxCrossCircled } from "react-icons/rx";
import { useDispatch } from "react-redux";
import {
  CancelSubscription,
  VerifiySubscription,
} from "../../ReduxToolkit/Slices/Subscription/Subscription.action";
import toast from "react-hot-toast";
const ConformationPopup = ({ ShowConformModal, data }) => {
  const modalRef = useRef(null);
  let dispatch = useDispatch();

  let [isactionload, setisactionload] = useState(false);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        ShowConformModal(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [ShowConformModal]);

  if (!ShowConformModal) {
    return null; // Don't render the modal if the state is false
  }

  let CancelThisSubscription = () => {
    let subformdata = new FormData();
    subformdata.append("transaction_id", data?.transaction_id);
    setisactionload(true);
    dispatch(CancelSubscription(subformdata))
      .unwrap()
      .then((result) => {
        if (result?.s == 1) {
          let SubscriptionData = new FormData();
          SubscriptionData?.append("id", data?.transaction_id);
          SubscriptionData?.append("type", data?.plan_type);
          dispatch(VerifiySubscription(SubscriptionData))
            .unwrap()
            .then((result) => {
              if (result?.s == 1) {
                setisactionload(false);
                ShowConformModal();
                toast.success(
                  "Subscription plan has been cancelled successfully!"
                );
                window.location.reload();
              } else {
                ShowConformModal();
                toast.error("Something wents wrong !");
              }
            });
        } else {
          toast.error(result?.m);
          ShowConformModal();
        }
      });
  };

  return (
    <div className="fixed z-50 inset-0 bg-[rgba(54,0,17,0.84)] flex items-center justify-center">
      <div ref={modalRef}>
        <div className="bg-white rounded-[32px] p-10 relative w-[90%] sm:w-[500px] tracking-wide">
          <div className="flex justify-end absolute -right-8 -top-6 ">
            <RxCrossCircled
              style={{ width: "25px", height: "25px", cursor: "pointer",color:"#fff" }}
              onClick={() => ShowConformModal()}
            />
          </div>
          <p className="text-lg py-4 font-semibold">Are you sure want to cancel this Subscription ?</p>
          <div className="flex gap-4 mt-4">
            <button
              disabled={isactionload}
              onClick={() => CancelThisSubscription()}
              className={` w-full  rounded-[8px] border-2 border-primary-blue size-fit px-4 py-3 bg-primary-blue hover:bg-primary-dark-blue  text-white ease-in-out duration-500`}
            >
              {isactionload ? "Please Wait" : "Yes"}{" "}
            </button>
            <button
              onClick={() => ShowConformModal()}
              className={`cursor-pointer  w-full rounded-[8px] border-2   size-fit px-4 py-3 bg-[#e86565]  text-white ease-in-out duration-500`}
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConformationPopup;
