import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseUrl } from "../../Apis";
let getUserdata=JSON.parse(localStorage.getItem("SaveOn_Web_Cread"))
export const GetsubscriptionPlans = createAsyncThunk(
  "subscription/GetsubscriptionPlans",
  async () => {
    try {
      let data = await axios.get(`${BaseUrl}/api/plan/get-plans`);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const CreatThisplanOrder = createAsyncThunk(
  "subscription/CreatThisplanOrder",
  async (planData) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${BaseUrl}/api/plan/create-order`,
        planData,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const CreateOrderSubscription = createAsyncThunk(
  "subscription/CreateOrderSubscription",
  async (planData) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${BaseUrl}/api/plan/buy-subscription`,
        planData,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const VerifiySubscription = createAsyncThunk(
  "subscription/VerifiySubscription",
  async (tokendata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${BaseUrl}/api/plan/verify`,
        tokendata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const CancelSubscription = createAsyncThunk(
  "subscription/CancelSubscription",
  async (subscridataption) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${BaseUrl}/api/plan/cancel-sub`,
        subscridataption,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const RedeemCoupen = createAsyncThunk(
  "subscription/RedeemCoupen",
  async (coupendata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${BaseUrl}/api/offer/redeem-coupon`,
        coupendata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const GetmyCurrentSubscription = createAsyncThunk(
  "subscription/GetmyCurrentSubscription",
  async (id) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${BaseUrl}/api/user/get-subscription`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const GetmyGiveaway = createAsyncThunk(
  "subscription/GetmyGiveaway",
  async (id) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(
        `${BaseUrl}/api/user/get-user-giveaways`,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
export const GetmyPaymentHistory = createAsyncThunk(
  "subscription/GetmyPaymentHistory",
  async (id) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(`${BaseUrl}/api/user/payment-history`, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const RateOrder = createAsyncThunk(
  "subscription/RateOrder",
  async (offerdata) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${BaseUrl}/api/user/add-offer-rating`,
        offerdata,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);

export const GetmyCheckoutDetails = createAsyncThunk(
  "subscription/GetmyCheckoutDetails",
  async (id) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.get(`${BaseUrl}/api/plan/get-checkout`, config);
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);





export const addupdatecheckoutDetails = createAsyncThunk(
  "subscription/addupdatecheckoutDetails",
  async (planData) => {
    try {
      let { apikey, token } = getUserdata;
      const config = {
        headers: {
          apikey,
          token,
        },
      };
      let data = await axios.post(
        `${BaseUrl}/api/plan/checkout`,
        planData,
        config
      );
      return data.data;
    } catch (error) {
      throw error;
    }
  }
);
