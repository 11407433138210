import React, { useEffect, useState } from "react";
import offerCard from "../../images/offerCard.png";
import scissor from "../../images/scissorTwo.svg";
import CouponModal from "../../components/modal/CouponModal";
import { useDispatch, useSelector } from "react-redux";
import { GetUserCoupens } from "../../ReduxToolkit/Slices/Deals/Deals.action";
import { BaseUrl } from "../../ReduxToolkit/Apis";
import moment from "moment";

const MyCoupons = () => {
  const [isCouponOpen, setIsCouponOpen] = useState(false);

  const closeCouponModal = () => setIsCouponOpen(false);

  let dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetUserCoupens());
  }, []);
  let DealsDetails = useSelector((store) => store?.Deals);
  return (
    <div className="grid grid-cols-3 lg:grid-cols-1  md:gap-y-10 lg:gap-y-14 m-auto items-center md:items-start justify-center md:justify-start flex-wrap gap-6">
      {isCouponOpen && <CouponModal closeCouponModal={closeCouponModal} />}
      {DealsDetails?.UserCoupens?.length == 0 ||
      DealsDetails?.UserCoupens == null ? (
        <div className="flex items-center justify-center w-full text-lg font-semibold">
          No Coupons !{" "}
        </div>
      ) : (
        <div className="tracking-wide flex items-center md:items-start justify-between md:justify-between flex-wrap gap-6">
          {DealsDetails?.UserCoupens?.map((coupon) => {
            return (
              <div key={coupon?.id} className="card">
                <div className="absolute flex flex-col justify-between h-full z-10 -left-2">
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                </div>
                <div className="absolute flex flex-col justify-between h-full z-10 -right-2">
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                  <div className="p-2 rounded-full bg-[#FAFAFA]" />
                </div>
                <div className="flex  w-[100%]">
                  <div className="w-[100%] md:w-[100%] h-[180px]">
                    <img
                      width={"100%"}
                      height={"100%"}
                      className="object-cover h-[inherit]"
                      src={`${BaseUrl}/${coupon?.image?.image}`}
                      alt="offer"
                    />
                  </div>
                  <div className="p-4 w-[60%] md:w-[60%] flex flex-col justify-center items-center bg-light-blue">
                    <div className="flex items-baseline gap-2 py-3 px-6 w-fit rounded-md bg-dark-blue">
                      <span className="text-text  text-white font-semibold text-2xl md:text-3xl">
                        ${coupon?.strike_amount}
                      </span>
                      <span className="text-white text-sm font-light line-through">
                        ${coupon?.real_amount}
                      </span>
                    </div>
                    <p className="text-dark-blue] text-xl md:text-2xl font-semibold mt-2">
                      {coupon?.name}
                    </p>
                    <p className="text-[rgba(19,87,121,0.7)] text-sm md:text-base font-medium mt-2">
                      {coupon?.expiry_date == null
                        ? "----"
                        : moment(coupon?.expiry_date)
                            .local()
                            .format("MMMM Do YYYY") || "--"}
                    </p>
                  </div>
                </div>
                <button className="relative w-full py-4 bg-dark-blue text-white text-xl md:text-2xl font-semibold border-t-2 border-[rgba(137,209,240,1)] border-dashed">
                  Download
                  <img
                    className="absolute -top-[20px] left-10"
                    src={scissor}
                    alt="scissor"
                  />
                </button>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MyCoupons;
