import React, { useState, useEffect } from "react";
import profileIcon from "../../images/profile.svg";
import editIcon from "../../images/edit.svg";
import showPass from "../../images/eye.svg";
import HidePass from "../../images/eyeTwo.svg";
import myCoupon from "../../images/myCoupon.svg";
import plansIcon from "../../images/plans.svg";
import giveawaysIcon from "../../images/giveaways.svg";
import paymentIcon from "../../images/payment.svg";
import myCouponActive from "../../images/myCouponActive.svg";
import plansActive from "../../images/plansActive.svg";
import giveawaysActive from "../../images/giveawaysActive.svg";
import paymentActive from "../../images/paymentActive.svg";
import couponPattern from "../../images/couponPattern.svg";

import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  ChangePassword,
  GetUserProfileDetails,
} from "../../ReduxToolkit/Slices/Auth/Auth.action";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GetUserCoupens } from "../../ReduxToolkit/Slices/Deals/Deals.action";
const MyAccount = () => {
  const [isDisable, setIsDisable] = useState(true);
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showRetypeNewPass, setShowRetypeNewPass] = useState(false);
  const [activeRoute, setActiveRoute] = useState("");
  const handleEdit = () => {
    setIsDisable(!isDisable);
  };
  const navigate = useNavigate();
  const tabDetails = [
    {
      id: "1",
      icon: myCoupon,
      activeIcon: myCouponActive,
      title: "My Coupons",
      route: "/myaccount",
    },
    {
      id: "2",
      icon: plansIcon,
      activeIcon: plansActive,
      title: "One-Off Plans",
      route: "/myaccount/oneoffplans",
    },
    {
      id: "3",
      icon: giveawaysIcon,
      activeIcon: giveawaysActive,
      title: "Subscription",
      route: "/myaccount/mygiveaways",
    },
    {
      id: "4",
      icon: paymentIcon,
      activeIcon: paymentActive,
      title: "Payment History",
      route: "/myaccount/paymenthistory",
    },
  ];
  let dispatch = useDispatch();
  let userDetails = JSON.parse(localStorage.getItem("SaveOn_Web_Cread"));
  useEffect(() => {
    const savedRoute = localStorage.getItem("activeRoute");
    if (savedRoute) {
      setActiveRoute(savedRoute);
      navigate(savedRoute);
    } else {
      setActiveRoute("/myaccount");
      navigate("/myaccount");
    }
    dispatch(GetUserProfileDetails(userDetails?.id));
    dispatch(GetUserCoupens());
  }, [navigate]);
  const navigateToRoute = (route) => {
    navigate(route);
    setActiveRoute(route);
    localStorage.setItem("activeRoute", route);
  };
  let userData = useSelector((store) => store?.Auth?.UserDetailsByid);
  let DealsDetails = useSelector((store) => store?.Deals);
  let [authload, setauthload] = useState(false);
  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      current_password: Yup.string().required("Current password is required!"),
      new_password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .required("New password is required!")
        .test(
          "not-same-as-current",
          "New password cannot be the same as the current password",
          function (value) {
            return value !== this.parent.current_password;
          }
        ),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("new_password"), null], "Passwords must match")
        .required("Confirm password is required!"),
    }),
    onSubmit: (values, { resetForm }) => {
      let PasswordDetails = new FormData();
      PasswordDetails.append("current_password", values?.current_password);
      PasswordDetails.append("new_password", values?.new_password);
      setauthload(true);
      dispatch(ChangePassword(PasswordDetails))
        .unwrap()
        .then((result) => {
          setauthload(false);
          if (result?.s == 1) {
            toast(`${result?.m} !`, {
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
                textAlign: "center",
              },
            });
            resetForm(); // Reset the form upon successful submission
          } else {
            toast(result?.m, {
              style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
              },
            });
          }
        });
    },
  });
  return (
    <div className="tracking-wide">
      <div className="w-full mt-[73px] border-b-[rgba(110,129,141,0.4)] border-b bg-[#F3F3F3]">
        <div className="w-[90%] max-w-[1500px] mx-auto flex flex-col sm:flex-row gap-8 sm:gap-4 justify-between items-center py-8">
          <div className="flex items-center gap-2">
            <img src={profileIcon} alt="profile" />
            <span className="text-dark-text text-lg font-semibold">
              My Account
            </span>
          </div>
          <div className="cardTwo bg-gradient-to-r from-[rgba(209,5,87,1)] to-[rgba(255,159,211,1)] w-[280px] py-4 flex flex-col items-center justify-center text-white text-center">
            <img
              className="absolute top-0 left-0"
              src={couponPattern}
              alt="pattern"
            />
            <div className="absolute flex flex-col justify-between h-full z-10 -left-2">
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
            </div>
            <div className="absolute flex flex-col justify-between h-full z-10 -right-2">
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
              <div className="p-2 rounded-full bg-[#F3F3F3]" />
            </div>
            <div>
              <p className="text-base">You have</p>
              <p className="text-2xl font-semibold">
                {userData?.available_token} Coupons
              </p>
              <p className="text-xl">Left</p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row w-[90%] max-w-[1500px] mx-auto gap-10 items-center justify-between py-14">
        {/* -------- Personal Details Section -------- */}
        <div className="w-full md:w-[90%]  lg:w-[40%] xl:w-[40%]">
          <h3 className="text-dark-blue text-c_lg">Personal Details</h3>

          {/* -------- Name Field -------- */}
          <div className="mt-6 relative">
            <input
              className="focus:border-primary-blue rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
              type="text"
              name="name"
              id="name"
              placeholder="John Doe"
              value={userData?.name}
              // onChange={(e) => setName(e.target.value)}
              disabled
              style={{
                background: "rgba(254,241,248,1)",
                color: "rgba(209, 5, 87, 1)",
              }}
            />
          </div>

          {/* -------- Region Field -------- */}
          <div className="mt-6 relative">
            <input
              className="focus:border-primary-blue rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
              type="text"
              name="region"
              id="region"
              placeholder="Europe"
              value={userData?.region}
              // onChange={(e) => setRegion(e.target.value)}
              disabled
              style={{
                background: "rgba(254,241,248,1)",
                color: "rgba(209,5,87,1)",
              }}
            />
          </div>

          {/* -------- Email Field -------- */}
          <div className="mt-6 relative">
            <input
              className="rounded-[10px] px-4 py-4 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
              type={"email"}
              placeholder="Email Address"
              name="email"
              id="email"
              value={userData?.email}
              // onChange={(e) => setEmail(e.target.value)}
              disabled
              style={{
                background: "rgba(254,241,248,1)",
                color: "rgba(209, 5, 87, 1)",
              }}
            />
          </div>

          {/* -------- Address Field -------- */}
          <div className="mt-6 relative">
            <input
              className="focus:border-primary-blue rounded-[10px] px-4 pt-4 pb-24 w-full focus:outline-none focus:ring-0 hover:outline-none hover:ring-0"
              type="text"
              name="address"
              id="address"
              placeholder="Address"
              value={userData?.address}
              disabled
              // onChange={(e) => setAddress(e.target.value)}
              style={{
                background: "rgba(254,241,248,1)",
                color: "rgba(209, 5, 87, 1)",
              }}
            />
          </div>
        </div>

        <div className="w-full md:w-[90%] max-w-[1500px] mx-auto lg:w-[50%] xl:w-[40%] border-dashed border-2 border-[rgba(137,105,120,1)] p-8 lg:p-14 rounded-[64px]">
          <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2">
            <h3 className="mb-4 text-primary-blue text-xl md:text-xl lg:text-2xl font-semibold">
              Want to change Password?
            </h3>
            {/* -------- Current Password Field -------- */}

            {/* <label
                htmlFor="currentPassword"
                className="text-left text-[rgba(19,87,121,1)]  font-semibold"
              >
                Current Password
              </label>

              <div className="mt-2  flex flex-col gap-2 border-2">
                <input
                  className="focus:border-primary-blue rounded-[10px] border border-solid border-[rgba(0,0,0,0.2)] w-full h-[56px] p-5"
                  placeholder="Current Password"
                  name="current_password"
                  type={showCurrentPass ? "text" : "password"}
                  value={formik.values.current_password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <img
                  className="absolute right-3 top-4 cursor-pointer"
                  src={ showPass}
                  alt="toggle visibility"
                  onClick={() => setShowCurrentPass(!showCurrentPass)}
                />
                {formik.touched.current_password &&
                formik.errors.current_password ? (
                  <div className="text-red-600 text-sm">
                    {formik.errors.current_password}
                  </div>
                ) : null}
              </div>
      */}

            {/* -------- Current Password Field -------- */}
            <label
              htmlFor="current_password"
              className="text-left text-dark-text  font-semibold"
            >
              Current Password
            </label>
            <div className="mt-2 relative">
              <input
                className="focus:border-primary-blue rounded-[10px] border border-solid border-[rgba(0,0,0,0.2)] w-full h-[56px] p-5"
                placeholder="Current Password"
                name="current_password"
                type={showCurrentPass ? "text" : "password"}
                value={formik.values.current_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{
                  background: "rgba(254,241,248,1)",
                  color: "rgba(209, 5, 87, 1)",
                }}
              />
              <img
                className="absolute right-3 top-4 cursor-pointer"
                src={showCurrentPass ? HidePass : showPass}
                alt="toggle visibility"
                onClick={() => setShowCurrentPass(!showCurrentPass)}
              />
              {formik.touched.current_password &&
              formik.errors.current_password ? (
                <div className="text-red-600 text-sm">
                  {formik.errors.current_password}
                </div>
              ) : null}
            </div>

            {/* -------- New Password Field -------- */}
            <label
              htmlFor="new_password"
              className="text-left text-dark-text font-semibold"
            >
              Type New password
            </label>
            <div className="mt-2 relative">
              <input
                className="focus:border-primary-blue rounded-[10px] border border-solid border-[rgba(0,0,0,0.2)] w-full h-[56px] p-5"
                placeholder="New Password"
                name="new_password"
                type={showNewPass ? "text" : "password"}
                value={formik.values.new_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{
                  background: "rgba(254,241,248,1)",
                  color: "rgba(209, 5, 87, 1)",
                }}
              />
              <img
                className="absolute right-3 top-4 cursor-pointer"
                src={showNewPass ? HidePass : showPass}
                alt="toggle visibility"
                onClick={() => setShowNewPass(!showNewPass)}
              />
              {formik.touched.new_password && formik.errors.new_password ? (
                <div className="text-red-600 text-sm">
                  {formik.errors.new_password}
                </div>
              ) : null}
            </div>

            {/* -------- Retype New Password Field -------- */}
            <label
              htmlFor="currentPassword"
              className="text-left text-dark-text  font-semibold"
            >
              Re-Type New password
            </label>
            <div className="mt-2 relative">
              <input
                className="focus:border-primary-blue rounded-[10px] border border-solid border-[rgba(0,0,0,0.2)] w-full h-[56px] p-5"
                placeholder="Retype New Password"
                name="confirmPassword"
                type={showRetypeNewPass ? "text" : "password"}
                value={formik.values.confirmPassword}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                style={{
                  background: "rgba(254,241,248,1)",
                  color: "rgba(209, 5, 87, 1)",
                }}
              />
              <img
                className="absolute right-3 top-4 cursor-pointer"
                src={showRetypeNewPass ? HidePass : showPass}
                alt="toggle visibility"
                onClick={() => setShowRetypeNewPass(!showRetypeNewPass)}
              />
              {formik.touched.confirmPassword &&
              formik.errors.confirmPassword ? (
                <div className="text-red-600 text-sm">
                  {formik.errors.confirmPassword}
                </div>
              ) : null}
            </div>
            {/* -------- Submit Button -------- */}
            <button
              disabled={authload}
              type="submit"
              className="mt-6 bg-primary-blue text-white rounded-[10px] w-1/2 h-[56px] hover:bg-dark-blue transition-colors"
            >
              {authload ? "Loading" : "Submit"}
            </button>
          </form>
        </div>
      </div>
      {/* -------- Tabs Section Section -------- */}
      <div className="w-full border-[rgba(110,129,141,0.14)] border pt-10">
        <div className="w-[90%] max-w-[1500px] mx-auto">
          <ul className="flex flex-wrap -mb-px text-sm font-medium text-center text-gray-500">
            {tabDetails?.map((tab) => {
              const isActive = tab?.route === activeRoute;
              return (
                <li
                  onClick={() => navigateToRoute(tab?.route)}
                  key={tab?.id}
                  className="me-2 cursor-pointer"
                >
                  <div
                    className={`inline-flex gap-2 items-center justify-center p-4 border-b-2 rounded-b-lg rounded-t-lg hover:text-[#154A65] hover:border-gray-300 group ${
                      isActive
                        ? "border-primary-blue text-primary-blue"
                        : "border-transparent text-dark-text"
                    } `}
                  >
                    <img
                      className="w-[30px]"
                      src={isActive ? tab?.activeIcon : tab?.icon}
                      alt={tab?.title}
                    />
                    <span>{tab?.title}</span>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>

      <div className="bg-[#FAFAFA] w-full py-14">
        <div className="w-[90%] max-w-[1500px] mx-auto">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
