import { useEffect, useState } from "react";

import partnersimg from "../../images/amazon.png";
import locationlogo from "../../images/location.svg";
import phonelogo from "../../images/phone.svg";
import gmailLogo from "../../images/gmail.svg";
import clocklogo from "../../images/clock.svg";
import emptyStar from "../../images/starOutline.svg";
import filledStar from "../../images/starFilled.svg";
import web from "../../images/web.svg";
import dawonloades from "../../images/dawonloades.svg";
import { Rating } from 'react-simple-star-rating'
import scissorIcon from "../../images/scissor.svg";
import listDetailsIcon from "../../images/listDetails.svg";
import linearIcon from "../../images/linear.svg";
import highlight from "../../images/highlight.svg";
import dropArrow from "../../images/dropArrow.svg";
import Slider from "../../components/Slider";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetOffersDetailsByCategoryId } from "../../ReduxToolkit/Slices/Offers/Offers.action";
import { BaseUrl } from "../../ReduxToolkit/Apis";
import { GetPartnerTermsandConditions } from "../../ReduxToolkit/Slices/Partners/Partners.action";
import moment from "moment";
import { RedeemCoupen } from "../../ReduxToolkit/Slices/Subscription/Subscription.action";
import { GetUserProfileDetails } from "../../ReduxToolkit/Slices/Auth/Auth.action";
import toast from "react-hot-toast";
import "../../components/Animationcss.css";
import ReactStars from "react-rating-stars-component";

import CouponModal from "../../components/modal/CouponModal";
import RedeemModal from "../../components/modal/RedeemModal";
import LoginModal from "../../components/modal/LoginModal";
import SignupModal from "../../components/modal/SignupModal";
import ForgotPassword from "../../components/modal/ForgotPassword";
import fbIcon from "../../images/Facebook.svg";
import tiktokIcon from "../../images/Tiktok.svg";
import instaIcon from "../../images/Instagram.svg";
import twitter from "../../images/twitter.svg";
import { discountcalculate } from "../../Funtions/CommonFunc";
const OfferDetails = () => {
  const [openIndex, setOpenIndex] = useState(1);
  let dispatch = useDispatch();
  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const [isCouponOpen, setIsCouponOpen] = useState(false);
  const closeCouponModal = () => setIsCouponOpen(false);
  const [isRedeemOpen, setIsRedeemOpen] = useState(false);
  let [coupenData, setcoupenData] = useState(null);
  let location = useLocation();
  const idMatch = location?.pathname.match(/\/offers\/(\d+)$/);
  const id = idMatch ? idMatch[1] : null;
  let logindata = JSON.parse(localStorage.getItem("SaveOn_Web_Cread"));
  let [redeemLoader, setredeemLoader] = useState(false);
  useEffect(() => {
    dispatch(GetOffersDetailsByCategoryId(id));
    dispatch(GetPartnerTermsandConditions(id));
    dispatch(GetUserProfileDetails(logindata?.id));
    window.scrollTo(0, 0);
  }, []);
  let OfferDetails = useSelector((store) => store?.Offers);
  let PartnerDetails = useSelector((store) => store?.Partners);
  let Authdata = useSelector((store) => store?.Auth);

  // -------- Is Check Login -------------
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const openSignupModal = () => setIsSignupOpen(true);
  const closeSignupModal = () => setIsSignupOpen(false);

  const openLoginModal = () => setIsLoginOpen(true);
  const closeLoginModal = () => setIsLoginOpen(false);

  const openForgotPassModal = () => setIsForgotPasswordOpen(true);
  const closeForgotPassModal = () => setIsForgotPasswordOpen(false);

  const switchToForgotPass = () => {
    closeLoginModal();
    openForgotPassModal();
  };
  const switchToLogin = () => {
    closeSignupModal();
    closeForgotPassModal();
    openLoginModal();
  };
  const switchToSignup = () => {
    closeLoginModal();
    openSignupModal();
  };

  const CheckIsLogin = () => {
    const isLoggedIn = localStorage.getItem("SaveOn_Web_Cread");
    if (isLoggedIn) {
      return true;
    } else {
      return false;
    }
  };

  let RedeemThisCoupen = () => {
    let Checklogin = localStorage.getItem("SaveOn_Web_Cread");
    console.log(Checklogin);
    if (Checklogin !== null) {
      if (Authdata?.UserDetailsByid?.available_token == 0) {
        toast.error("No coupons left. Please buy coupons!");
        setIsRedeemOpen(true);
      } else {
        setIsRedeemOpen(false);
        setredeemLoader(true);
        let coupenData = new FormData();
        coupenData?.append("offer_id", id);
        dispatch(RedeemCoupen(coupenData))
          .unwrap()
          .then((result) => {
            if (result?.s == 1) {
              setredeemLoader(false);
              dispatch(GetUserProfileDetails(logindata?.id));
              toast.success(
                `Coupen redeem sucessfully , now you have ${
                  Authdata?.UserDetailsByid?.available_token === 0
                    ? 0
                    : Authdata?.UserDetailsByid?.available_token - 1
                } coupens left ! `
              );
              setcoupenData({
                coupendata: result?.r,
                offerData: OfferDetails?.OfferDetailsById,
              });
              setIsCouponOpen(true);
            } else {
              toast.error(result?.m);
              setredeemLoader(false);
            }
          });
      }
    } else {
      toast("Please Login to redeem this coupen!", {
        icon: "��",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      });
      setIsRedeemOpen(false);
      openLoginModal();
    }
  };
  const [isExpanded, setIsExpanded] = useState(false);
  const description =
    OfferDetails?.OfferDetailsById?.partner_details?.description || ".....";

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <section className="bg-bg-color">
      <div className="h-[calc(100vh-72px)] mt-[72px] bg-slate-600">
        <Slider data={OfferDetails?.OfferDetailsById?.images} />
      </div>

      {redeemLoader && (
        <div className="fixed z-50 top-0 left-0 w-full h-full flex items-center justify-center  bg-black bg-opacity-50">
          <span className="loader"></span>
        </div>
      )}
      {isCouponOpen && (
        <CouponModal closeCouponModal={closeCouponModal} data={coupenData} />
      )}
      {/* --- Partner details content Section ---- */}
      <div className="flex flex-col lg:flex-row w-full">
        <div className="bg-slate-50 flex w-full lg:w-3/5 justify-center gap-10 p-8 lg:p-16">
          <div className="w-full flex flex-col sm:flex-row justify-start lg:justify-center items-start gap-6 md:gap-14">
            <img
              src={
                OfferDetails?.OfferDetailsById?.partner_image
                  ? `${BaseUrl}${OfferDetails?.OfferDetailsById?.partner_image[0]?.image}`
                  : ""
              }
              alt="partner_Logo"
              className="w-[120px] md:w-[200px] lg:w-[250px] xl:w-[306px] h-44 object-cover"
            />
            <div className="flex flex-col justify-start gap-4 w-full md:w-1/2">
              <h2 className="text-cx lg:text-cx xl:text-c_x text-dark-blue">
                {OfferDetails?.OfferDetailsById?.partner_details?.name
                  ? OfferDetails?.OfferDetailsById?.partner_details?.name
                  : "------"}
              </h2>

              <p
                className={`text-[#6E818D] ${
                  isExpanded ? "" : "line-clamp-2 overflow-hidden"
                } text-dark-text text-csm lg:text-c_sm`}
                dangerouslySetInnerHTML={{ __html: description }}
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: isExpanded ? "unset" : 2, // Show 2 lines initially
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                }}
              />
              {description && (
                <span
                  onClick={toggleDescription}
                  className="text-primary-blue cursor-pointer"
                >
                  {isExpanded ? "View Less" : "View More"}
                </span>
              )}
              <div className="flex gap-2 ">
                {OfferDetails?.OfferDetailsById?.partner_details?.f_url !==
                null ? (
                  <a
                    href={
                      OfferDetails?.OfferDetailsById?.partner_details?.f_url
                    }
                    className="cursor-pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="w-[40px]" src={fbIcon} alt="facebook" />
                  </a>
                ) : (
                  ""
                )}
                {OfferDetails?.OfferDetailsById?.partner_details?.i_url !==
                null ? (
                  <a
                    href={
                      OfferDetails?.OfferDetailsById?.partner_details?.i_url
                    }
                    className="cursor-pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="w-[40px]" src={instaIcon} alt="instagram" />
                  </a>
                ) : (
                  ""
                )}
                {OfferDetails?.OfferDetailsById?.partner_details?.x_url !==
                null ? (
                  <a
                    href={
                      OfferDetails?.OfferDetailsById?.partner_details?.x_url
                    }
                    className="cursor-pointer"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img className="w-[40px]" src={twitter} alt="instagram" />
                  </a>
                ) : (
                  ""
                )}

                {/* <a
                  href="https://facebook.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img className="w-[40px]" src={tiktokIcon} alt="tiktok" />
                </a> */}
              </div>
              <div className="  w-1/2 h-[40px] flex items-center gap-2 -mt-4">
              {OfferDetails?.OfferDetailsById?.rating? <ReactStars
                  count={5}
                  value={Math.floor(OfferDetails?.OfferDetailsById?.rating)}
                  size={34}
                  edit={false}
                  activeColor="#ffd700"
                />:""}
                <span className="text-primary-blue font-semibold text-[18px] mt-2">
                  {OfferDetails?.OfferDetailsById?.rating}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-[rgba(254,241,248,1)] flex w-full lg:w-2/5 flex-col gap-6 p-8 lg:p-16">
          <p className="text-c_md text-dark-text font-semibold"> Address</p>
          <div className="flex gap-2 items-start">
            <img
              src={locationlogo}
              alt="location logo"
              className="w-[25px] h-[25px]"
            />
            <p className="text-csm lg:text-c_sm text-dark-blue">
              {OfferDetails?.OfferDetailsById?.partner_details?.address
                ? OfferDetails?.OfferDetailsById?.partner_details?.address
                : "------"}
            </p>
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex gap-2 items-center">
              <img
                src={phonelogo}
                alt="phone logo"
                className="w-[25px] h-[25px]"
              />
              <p className="text-csm lg:text-c_sm text-dark-blue">
                +
                {OfferDetails?.OfferDetailsById?.partner_details?.phno_cc
                  ? OfferDetails?.OfferDetailsById?.partner_details?.phno_cc
                  : "------"}{" "}
                <span>
                  {" "}
                  {OfferDetails?.OfferDetailsById?.partner_details?.phno
                    ? OfferDetails?.OfferDetailsById?.partner_details?.phno
                    : "------"}
                </span>
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <img
                src={gmailLogo}
                alt="gmail logo"
                className="w-[25px] h-[25px]"
              />
              <p className="text-csm lg:text-c_sm text-dark-blue">
                {OfferDetails?.OfferDetailsById?.partner_details?.email
                  ? OfferDetails?.OfferDetailsById?.partner_details?.email
                  : "------"}
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-6">
            <div className="flex gap-2 items-center">
              <img
                src={clocklogo}
                alt="clock logo"
                className="w-[25px] h-[25px]"
              />
              <p className="text-csm lg:text-c_sm text-dark-blue">
                <span>
                  {moment(
                    OfferDetails?.OfferDetailsById?.partner_details?.start_time,
                    "HH:mm:ss"
                  )
                    .local()
                    .format("hh:mm A") || "--"}
                  -{" "}
                  {moment(
                    OfferDetails?.OfferDetailsById?.partner_details?.end_time,
                    "HH:mm:ss"
                  )
                    .local()
                    .format("hh:mm A") || "--"}
                </span>{" "}
              </p>
            </div>
            <div className="flex gap-2 items-start">
              {OfferDetails?.OfferDetailsById?.partner_details?.web_url !==
              null ? (
                <a
                  href={
                    OfferDetails?.OfferDetailsById?.partner_details?.web_url
                  }
                  className="cursor-pointer flex gap-2 items-start"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={web}
                    alt="location logo"
                    className="w-[25px] h-[25px]"
                  />
                  <p className="text-csm lg:text-c_sm text-dark-blue">
                    {OfferDetails?.OfferDetailsById?.partner_details?.web_url
                      ? OfferDetails?.OfferDetailsById?.partner_details?.web_url
                      : "------"}
                    No Data
                  </p>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      {/* --- Discount Card Section ---- */}
      <div className="h-full w-full bg-dark-blue p-4">
        <div className="border-dashed border-2 h-full w-full rounded-xl relative py-4">
          <img
            className="absolute -left-3 top-2/4"
            src={scissorIcon}
            alt="scissor icon"
          />
          <img
            className="absolute -right-3 top-2/4 rotate-180"
            src={scissorIcon}
            alt="scissor icon"
          />
          <div className="flex flex-wrap justify-end md:justify-between gap-2 items-center h-full w-full md:w-[95%] lg:w-[90%] max-w-[1500px] mx-auto px-8 text-white">
            <div className="flex flex-col ">
              <h3 className="text-clg lg:text-c_lg xl:text-cxl tracking-wider">
                {OfferDetails?.OfferDetailsById?.title}
              </h3>
              <p className="text-c_sm lg:text-cmd xl:text-2xl mb-2 tracking-wide">
                {OfferDetails?.OfferDetailsById?.subtitle}
              </p>
              <div className="flex flex-col gap-4 justify-start items-start">
                <button
                  disabled={redeemLoader}
                  onClick={RedeemThisCoupen}
                  className="cursor-pointer before:block before:absolute before:-inset-1 before:top-4 before:left-4 before:-bottom-2 before:-right-2 before:rounded-lg before:bg-primary-dark-blue relative inline-block"
                >
                  <div className="relative px-10 py-3 bg-white w-fit   rounded-lg">
                    <span
                      onClick={() => setIsRedeemOpen(true)}
                      className="text-dark-blue text-c_sm"
                    >
                      {redeemLoader ? "Please wait !!" : "Redeem Now"}
                    </span>
                  </div>
                </button>
                <div className="flex items-center gap-2">
                  <img
                    className="w-[30px] lg:w-[40px]"
                    src={dawonloades}
                    alt="user icon"
                  />
                  <span className="text-white font-semibold text-[14px] lg:text-[16px]">
                    {OfferDetails?.OfferDetailsById?.used_coupon_count
                      ? OfferDetails?.OfferDetailsById?.used_coupon_count
                      : 0}{" "}
                    Downloads
                  </span>
                </div>
                <div className="mt-2" />
              </div>
            </div>
            <div className="w-[20%] flex flex-col gap-4">
              <div className="flex items-center justify-between text-dark-text ">
                <h2 className="text-xmd lg:text-xl   text-[rgba(255,159,211,1)]">
                  Value
                </h2>
                <h2 className="text-xmd lg:text-xl  font-bold text-[rgba(255,159,211,1)]">
                  ${OfferDetails?.OfferDetailsById?.real_amount}
                </h2>
              </div>
              <div className="flex items-center justify-between text-dark-text  ">
                <h2 className="text-xmd lg:text-xl   text-[rgba(255,202,232,1)]">
                  Discount
                </h2>
                <h2 className="text-xmd lg:text-xl font-bold   text-[rgba(255,202,232,1)]">
                  {(discountcalculate(
                    OfferDetails?.OfferDetailsById?.real_amount,
                    OfferDetails?.OfferDetailsById?.strike_amount
                  ))}
                  %
                </h2>
              </div>
              <div className="flex items-center justify-between text-dark-text ">
                <h2 className="text-xmd lg:text-xl   text-white">You save </h2>
                <h2 className="text-xmd lg:text-xl font-bold  text-white">
                  $
                  {OfferDetails?.OfferDetailsById?.real_amount -
                    OfferDetails?.OfferDetailsById?.strike_amount}{" "}
                </h2>
              </div>
              <div className="flex  w-full gap-2 items-center px-2 py-2  justify-between bg-[rgba(255,255,255,0.24)] rounded-lg">
                <span className="text-sm">Total Price</span>
                <div className="flex items-baseline gap-2">
                  <span className="text-[24px] font-bold">
                    ${OfferDetails?.OfferDetailsById?.strike_amount}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --- Accordion Section ---- */}
      <div className="w-full ">
        <div className=" w-[90%] max-w-[1500px] mx-auto md:w-[70%] py-16">
          <div id="accordion-collapse" data-accordion="collapse">
            <h2 id="accordion-collapse-heading-1">
              <button
                type="button"
                className={`flex items-center ${
                  openIndex === 1 ? "bg-secondary-blue" : "transparent"
                } justify-between w-full p-5 font-medium rtl:text-right text-gray-500 rounded-md border-b border-b-primary-blue hover:border-b-primary-dark-blue gap-3`}
                onClick={() => toggleAccordion(1)}
                aria-expanded={openIndex === 1}
                aria-controls="accordion-collapse-body-1"
              >
                <div className="flex gap-2">
                  <img src={listDetailsIcon} alt="details icon" />
                  <span className="text-dark-text font-semibold">Details</span>
                </div>
                <img
                  className={`transition-transform duration-300 ${
                    openIndex === 1 && "rotate-180"
                  }`}
                  src={dropArrow}
                  alt="dropdown arrow"
                />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-1"
              className={`${
                openIndex === 1 ? "block" : "hidden"
              } p-5 text-csm rtl:text-right text-gray-500 bg-white`}
              aria-labelledby="accordion-collapse-heading-1"
            >
              <p className="mb-2 text-primary-blue">
                Here are the details of the offer.
              </p>

              <p
                className="text-[#6E818D]   "
                dangerouslySetInnerHTML={{
                  __html: OfferDetails?.OfferDetailsById?.description
                    ? OfferDetails?.OfferDetailsById?.description
                    : ".....",
                }}
              />
            </div>
            <h2 id="accordion-collapse-heading-1">
              <button
                type="button"
                className={`flex items-center ${
                  openIndex === 2 ? "bg-secondary-blue" : "transparent"
                } justify-between w-full p-5 font-medium rtl:text-right text-gray-500 rounded-md border-b border-b-primary-blue hover:border-b-primary-dark-blue gap-3`}
                onClick={() => toggleAccordion(2)}
                aria-expanded={openIndex === 2}
                aria-controls="accordion-collapse-body-1"
              >
                <div className="flex gap-2">
                  <img src={highlight} alt="details icon" />
                  <span className="text-dark-text font-semibold">
                    Highlights
                  </span>
                </div>
                <img
                  className={`transition-transform duration-300 ${
                    openIndex === 2 && "rotate-180"
                  }`}
                  src={dropArrow}
                  alt="dropdown arrow"
                />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-1"
              className={`${
                openIndex === 2 ? "block" : "hidden"
              } p-5 text-csm rtl:text-right text-gray-500 bg-white`}
              aria-labelledby="accordion-collapse-heading-1"
            >
              <p className="mb-2 text-primary-blue">
                Here are the highlights of the offer.
              </p>

              <p
                className="text-[#6E818D]   "
                dangerouslySetInnerHTML={{
                  __html: OfferDetails?.OfferDetailsById?.highlights
                    ? OfferDetails?.OfferDetailsById?.highlights
                    : ".....",
                }}
              />
            </div>
            <h2 id="accordion-collapse-heading-2">
              <button
                type="button"
                className={`flex items-center ${
                  openIndex === 3 ? "bg-secondary-blue" : "transparent"
                } justify-between w-full p-5 font-medium rtl:text-right text-gray-500 rounded-md border-b border-b-primary-blue hover:border-b-primary-dark-blue gap-3`}
                onClick={() => toggleAccordion(3)}
                aria-expanded={openIndex === 3}
                aria-controls="accordion-collapse-body-2"
              >
                <div className="flex gap-2">
                  <img src={linearIcon} alt="details icon" />
                  <span className="text-dark-text  font-semibold">
                    Terms and Conditions
                  </span>
                </div>
                <img
                  className={`transition-transform duration-300 ${
                    openIndex === 3 && "rotate-180"
                  }`}
                  src={dropArrow}
                  alt="dropdown arrow"
                />
              </button>
            </h2>
            <div
              id="accordion-collapse-body-2"
              className={`${
                openIndex === 3 ? "block" : "hidden"
              } p-5 text-csm rtl:text-right text-gray-500 bg-white`}
              aria-labelledby="accordion-collapse-heading-2"
            >
              <p className="mb-2 text-primary-blue">
                Here are the terms and conditions of the offer.
              </p>
              <div
                dangerouslySetInnerHTML={{
                  __html: PartnerDetails?.PartnerTermsandConditions
                    ?.term_condition
                    ? PartnerDetails?.PartnerTermsandConditions?.term_condition
                    : ".....",
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* --- Redeem Modal ---- */}
      <RedeemModal
        showRedeemModal={isRedeemOpen}
        setShowRedeemModal={setIsRedeemOpen}
        Subscribed={Authdata?.UserDetailsByid?.is_subscribe}
      />

      {/* --- Login, Modals ---- */}
      {isLoginOpen && (
        <LoginModal
          closeLoginModal={closeLoginModal}
          switchToSignup={switchToSignup}
          switchToForgotPass={switchToForgotPass}
        />
      )}

      {/* ---  Signup  Modals ---- */}
      {isSignupOpen && (
        <SignupModal
          closeSignupModal={closeSignupModal}
          switchToLogin={switchToLogin}
        />
      )}

      {/* ---  Forgot Password Modals ---- */}
      {isForgotPasswordOpen && (
        <ForgotPassword
          closeForgotPassModal={closeForgotPassModal}
          switchToForgotPass={switchToForgotPass}
        />
      )}
    </section>
  );
};

export default OfferDetails;
