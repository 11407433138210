import React, { useEffect, useState } from "react";
import Logo from "../images/Logo.png";
import SignupIcon from "../images/userSignup.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SignupModal from "./modal/SignupModal";
import LoginModal from "./modal/LoginModal";
import { useDispatch } from "react-redux";
import { Logout } from "../ReduxToolkit/Slices/Auth/Auth.action";
import ForgotPassword from "./modal/ForgotPassword";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const openSignupModal = () => setIsSignupOpen(true);
  const closeSignupModal = () => setIsSignupOpen(false);

  const openLoginModal = () => setIsLoginOpen(true);
  const closeLoginModal = () => setIsLoginOpen(false);

  const openForgotPassModal = () => setIsForgotPasswordOpen(true);
  const closeForgotPassModal = () => setIsForgotPasswordOpen(false);

  const switchToForgotPass = () => {
    closeLoginModal();
    openForgotPassModal();
  };
  const switchToLogin = () => {
    closeSignupModal();
    closeForgotPassModal();
    openLoginModal();
  };
  const switchToSignup = () => {
    closeLoginModal();
    openSignupModal();
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    if (!menuOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  };

  const currentLocation = useLocation();
  const isMyAccount = currentLocation.pathname === "/myaccount";
  const options = [
    { name: "Home", id: 1, route: "/" },
    { name: "Offers", id: 2, route: "/offers" },
    { name: "Giveaways", id: 3, route: "/giveaways" },
    { name: "About us", id: 4, route: "/aboutus" },
  ];
  const navigate = useNavigate();
  const NavigateToRoute = (page, isMob) => {
    if (isMob) {
      navigate(page?.route);
      setMenuOpen(false);
      document.body.classList.remove("overflow-hidden");
    } else {
      navigate(page?.route);
    }
  };
  const getInitials = (name) => {
    if (!name) return "";
    const nameArray = name.split(" ");
    const initials = nameArray.map((n) => n[0]).join("");
    return initials.toUpperCase();
  };

  const resdata = JSON.parse(localStorage.getItem("SaveOn_Web_Cread"));
  const initials = getInitials(resdata?.name);
  const dispatch = useDispatch();

  const handleSignout = () => {
    dispatch(Logout());
    navigate("/");
  };

  useEffect(() => {}, [resdata]);

  return (
    <header>
      <style>
        {`
          .dropdown:focus-within .dropdown-menu {
            opacity: 1;
            transform: translate(0) scale(1);
            visibility: visible;
          }
        `}
      </style>
      <nav className="bg-white fixed w-full z-50 top-0 start-0 shadow-md py-4 ">
        <div className="w-[90%] max-w-[1500px] mx-auto flex items-center justify-between  ">
          <Link
            to={"/"}
            className="flex items-center space-x-3 rtl:space-x-reverse"
          >
            <img
              alt="logo"
              className="w-[6rem] md:w-[8rem] xl:w-[12rem] max-w-[12rem]"
              src={Logo}
            />
          </Link>
          {resdata?.apikey ? (
            <div className="flex md:order-2 rtl:space-x-reverse">
              <div
                className="relative inline-block text-left dropdown"
                onMouseEnter={() => setIsDropdownOpen(true)}
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                <div
                  // onClick={() => navigate("/myaccount")}
                  className={`cursor-pointer border-2 relative inline-flex items-center justify-center w-10 h-10 overflow-hidden rounded-full ${
                    currentLocation?.pathname === "/myaccount"
                      ? "bg-primary-blue border-secondary-blue"
                      : "bg-gray-100 border-primary-blue"
                  }`}
                >
                  <span
                    className={`font-medium ${
                      currentLocation?.pathname === "/myaccount"
                        ? "text-white"
                        : "text-black-600"
                    }`}
                  >
                    {initials}
                  </span>
                </div>

                <div
                  className={`${
                    isDropdownOpen
                      ? "opacity-1 visible translate-y-0 scale-100"
                      : "opacity-0 invisible -translate-y-2 scale-95"
                  } transition-all duration-300 origin-top-right absolute right-0 w-56 mt-2 bg-white divide-y divide-gray-100 rounded-md shadow-lg outline-none dropdown-menu overflow-hidden`}
                  aria-labelledby="headlessui-menu-button-1"
                  id="headlessui-menu-items-117"
                  role="menu"
                >
                  <div className="px-4 py-3 border-b">
                    <p className="text-sm leading-5 font-medium ">Hello!</p>
                    <p className="text-sm font-medium leading-5 text-primary-blue truncate">
                      {resdata?.name}
                    </p>
                  </div>

                  <Link to={"/myaccount"}>
                    <div
                      className={`text-gray-700 flex group transition justify-between w-full py-2 text-sm hover:bg-primary-blue leading-5 text-left ${
                        isMyAccount ? "bg-primary-blue text-white" : ""
                      }`}
                    >
                      <button
                        tabIndex="3"
                        className="flex justify-between w-full group-hover:text-white px-4 py-2 text-sm leading-5 text-left"
                        role="menuitem"
                      >
                        My Profile
                      </button>
                    </div>
                  </Link>
                  <div className="py-2 group transition hover:bg-[#DE0000]/70">
                    <button
                      onClick={handleSignout}
                      tabIndex="3"
                      className="text-gray-700 group-hover:text-white flex justify-between w-full px-4 py-2 text-sm leading-5 text-left"
                      role="menuitem"
                    >
                      Sign out
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex md:order-2 sm:space-x-4 md:space-x-0 rtl:space-x-reverse gap-4 sm:gap-4">
              <button
                onClick={() => setIsLoginOpen(true)}
                className="text-[#0E2F43] font-medium sm:mr-2 underline"
              >
                {" "}
                Login
              </button>
              {isLoginOpen && (
                <LoginModal
                  closeLoginModal={closeLoginModal}
                  switchToSignup={switchToSignup}
                  switchToForgotPass={switchToForgotPass}
                />
              )}
              {isForgotPasswordOpen && (
                <ForgotPassword
                  closeForgotPassModal={closeForgotPassModal}
                  switchToForgotPass={switchToForgotPass}
                />
              )}
              <button
                onClick={() => setIsSignupOpen(true)}
                type="button"
                className="text-white bg-primary-blue rounded-lg text-xs md:text-base px-2 py-2 text-center flex items-center font-semibold tracking-wider"
              >
                <img
                  alt="SignupIcon"
                  className="w-[15px] sm:w-[20px] md:w-[25px]"
                  src={SignupIcon}
                />{" "}
                Signup
              </button>
              {isSignupOpen && (
                <SignupModal
                  closeSignupModal={closeSignupModal}
                  switchToLogin={switchToLogin}
                />
              )}
              <button
                onClick={toggleMenu}
                type="button"
                className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                aria-controls="navbar-sticky"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 17 14"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M1 1h15M1 7h15M1 13h15"
                  />
                </svg>
              </button>
            </div>
          )}
          <div
            className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
            id="navbar-sticky"
          >
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
              {options?.map((page, i) => (
                <li
                  key={i}
                  className={`${
                    currentLocation?.pathname === page?.route
                      ? "bg-primary-blue"
                      : ""
                  } ${
                    currentLocation?.pathname === page?.route
                      ? ""
                      : "hover:bg-[#faf1f6]"
                  } ease-in-out duration-500 rounded-md cursor-pointer`}
                >
                  <div
                    onClick={() => NavigateToRoute(page)}
                    className={`block py-2 px-3 ${
                      currentLocation?.pathname === page?.route
                        ? "text-[#fff]"
                        : "text-dark-text"
                    } rounded`}
                    aria-current="page"
                  >
                    {page?.name}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        {/* Mobile Nav */}
        <div
          className={`items-center py-4 backdrop-blur-[2px] shadow-2xl justify-between border absolute z-10 w-screen bg-transparent top-0 h-screen md:w-auto md:order-1 bg-gray-50 transition-transform duration-500 ${
            menuOpen ? "translate-x-0" : "-translate-x-[300%]"
          }`}
        >
          <div
            className={`items-center p-4 shadow-2xl justify-between border absolute z-10 w-[60%] top-0 h-screen md:w-auto md:order-1 bg-gray-50 transition-transform duration-500 ${
              menuOpen ? "translate-x-0" : "-translate-x-[300%]"
            }`}
            id="navbar-sticky"
          >
            <div className="flex items-center justify-between">
              <a
                href="#"
                className="flex items-center sm:space-x-3 rtl:space-x-reverse"
              >
                <img
                  className="w-[6rem] md:w-[8rem] xl:w-[12rem] max-w-[12rem]"
                  src={Logo}
                />
              </a>
              <button onClick={toggleMenu}>
                <svg
                  className="h-6 w-6 text-gray-400 cursor-pointer hover:text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <ul className="flex flex-col p-4 gap-2 mt-4 md:p-0 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white">
              {options?.map((page, i) => (
                <li
                  key={i}
                  className={`${
                    currentLocation?.pathname === page?.route
                      ? "bg-[rgba(35,161,212,1)]"
                      : ""
                  } ${
                    currentLocation?.pathname === page?.route
                      ? ""
                      : "hover:bg-[#E3F2FB]"
                  } ease-in-out duration-500 rounded-md w-[70%] text-start`}
                >
                  <div
                    onClick={() => NavigateToRoute(page, "mob")}
                    className={`block py-2 px-3 ${
                      currentLocation?.pathname === page?.route
                        ? "text-[#fff]"
                        : "text-[#15A65]"
                    } rounded`}
                    aria-current="page"
                  >
                    {page?.name}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
